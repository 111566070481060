










import UnderConstruction from "@/components/UnderConstruction.vue"
import { Vue, Component } from "vue-property-decorator"

@Component({
  name: "Home",
  components: {
    "under-construction": UnderConstruction,
  },
})
export default class Home extends Vue {}
