




import { Vue, Component } from "vue-property-decorator"

@Component({
  name: "under-construction",
})
export default class UnderConstruction extends Vue {}
